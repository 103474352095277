import React, { useRef, useState, useCallback } from 'react';
import authService from './services/auth.service';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

const SignInForm = (props) => {
  const { onAuthenticatedSignin } = props;
  const emailRef = useRef();
  const passwordRef = useRef();
  const [signingIn, setSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSignin = useCallback(() => {
    const username = emailRef.current?.value;
    const password = passwordRef.current?.value;

    setSigningIn(true);
    authService
      .signIn(username, password)
      .then((user) => {
        onAuthenticatedSignin && onAuthenticatedSignin();
        setErrorMessage(null);
      })
      .catch((e) => {
        console.log(e);
        setErrorMessage(e.message || 'Error Signing In.');
      })
      .finally(() => {
        setSigningIn(false);
      });
  }, [onAuthenticatedSignin]);

  return (
    <div className="card container bg-dark text-light d-flex flew-column mt-5" style={{ width: '35rem', borderRadius: '1rem' }}>
      <div className="card-body p-5 text-center">
        <h2>Sign In</h2>
        <p className="text-white-50 mb-5">Please enter your email and password!</p>
        <div className="form-outline form-white mb-4">
          <input type="email" ref={emailRef} placeholder="Email" className="form-control form-control-lg" disabled={signingIn} />
        </div>
        <div className="form-outline form-white mb-2">
          <input type="password" ref={passwordRef} placeholder="Password" className="form-control form-control-lg" disabled={signingIn} />
        </div>
        {errorMessage && !signingIn && <div style={{ borderRadius: '0.5rem', background: 'rgba(255, 50, 100, 0.4)' }}>{`${errorMessage}`}</div>}

        <Button size="lg" variant="primary" className="w-50 mt-2" onClick={onSignin} disabled={signingIn}>
          <div className="d-flex align-items-center justify-content-center">
            <div>Sign In</div>
            {signingIn && <Spinner as="div" animation="grow" size="sm" className="mx-2" />}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SignInForm;
