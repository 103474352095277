import axios from 'axios';
import AuthService from './auth.service.js';
import { API_PATH, API_ENTRYPOINTS } from './service.constants.js';

class TasksService {
  async getAllTasks() {
    return axios.get(`${API_PATH}/${API_ENTRYPOINTS.TASKS}`, { headers: await AuthService.authHeader() });
  }

  async startNewTask(taskParams) {
    // TODO: Always DIAMETER_CLIENT as type for now
    const taskType = 'DIAMETER_CLIENT';
    // TODO: A sample params object for now
    const params = { ...taskParams };

    return axios.post(
      `${API_PATH}/${API_ENTRYPOINTS.TASKS}`,
      { type: taskType, params: params },
      { headers: await AuthService.authHeader() }
    );
  }

  async stopTask(taskArn) {
    const taskArnAsBase64 = Buffer.from(`${taskArn}`).toString('base64');
    return axios.delete(`${API_PATH}/${API_ENTRYPOINTS.TASKS}/${taskArnAsBase64}`, { headers: await AuthService.authHeader() });
  }

  async getAllStats() {
    return axios.get(`${API_PATH}/${API_ENTRYPOINTS.STATS}`, { headers: await AuthService.authHeader() });
  }

  async getTaskStats(taskArn) {
    return axios.get(`${API_PATH}/${API_ENTRYPOINTS.STATS}/${taskArn}`, { headers: await AuthService.authHeader() });
  }

  async getTaskParams(taskArn) {
    return axios.get(`${API_PATH}/${API_ENTRYPOINTS.TASKPARAMS}/${taskArn}`, { headers: await AuthService.authHeader() });
  }
}

export default new TasksService();
