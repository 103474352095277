import React, { useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { FaTrash, FaSortUp, FaSortDown, FaStop } from 'react-icons/fa';
import TasksService from './services/tasks.service';
import moment from 'moment';
import NewTaskModal from './NewTaskModal';
import Spinner from 'react-bootstrap/Spinner';

const TASKS_REFRESH_PERIOD_MS = 5000;

const formatDate = (d) => {
  return moment(d).format('YYYY/MM/DD - HH:mm.SSS');
};

const OldTasksTable = (props) => {
  const { oldTasks, deleteTask, setTaskToShow } = props;
  const [sortCriteria, setSortCriteria] = useState({ field: 'createdAt', descending: true });

  function onSortClick(field) {
    setSortCriteria((oldSortCriteria) => {
      let newCriteria = { field: field, descending: true };

      if (oldSortCriteria?.field === field) {
        // Toggle order
        newCriteria.descending = oldSortCriteria?.descending ? false : true;
      }
      return { ...newCriteria };
    });
  }

  const sortedTasks = [...oldTasks];
  sortedTasks.sort((a, b) => {
    let result = -1;
    try {
      const d1 = Date.parse(a.createdAt);
      const d2 = Date.parse(b.createdAt);
      result = d1 - d2;
    } catch (e) {
      // Ignore erro
      console.log('error', e);
    }
    if (sortCriteria?.descending) result = result * -1;
    return result;
  });

  return (
    <>
      <h5 className="my-1 px-1">Completed Tasks</h5>
      <Table hover bordered>
        <thead className="bg-dark text-light small">
          <tr>
            <th style={{ width: '0.1%' }}>#</th>
            <th>Task ID</th>
            <th className="d-flex">
              <div className="d-flex flex-row align-items-center" style={{ cursor: 'pointer' }} onClick={() => onSortClick('createdAt')}>
                <div style={{ marginRight: '3px' }}>Created At</div>
                {sortCriteria?.descending ? <FaSortDown /> : <FaSortUp />}
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedTasks.map((t, i) => {
            return (
              <tr key={i} className="small">
                <td className="p-1 align-middle">{i + 1}</td>
                <td className="p-1  align-middle" style={{ width: '25rem' }}>
                  <Button variant="link" onClick={() => setTaskToShow(t)} className="m-0 p-0 font-monospace" size="sm">
                    {t.id}
                  </Button>
                </td>
                <td className="p-1  align-middle">
                  <div className="font-monospace">{formatDate(t.createdAt)}</div>
                </td>
                <td className="p-1  align-middle">
                  <Button
                    size="sm"
                    variant="danger"
                    className="align-items-center"
                    onClick={() => {
                      deleteTask && deleteTask(t.id);
                    }}
                  >
                    <FaTrash size="0.7em" />
                  </Button>
                </td>
              </tr>
            );
          })}
          {sortedTasks && sortedTasks.length === 0 && (
            <tr>
              <td colSpan="3">No Completed Tasks</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

const TaskDetails = (props) => {
  const { task, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [taskStats, setTaskStats] = useState(null);
  const [taskParams, setTaskParams] = useState(null);
  const taskId = task?.typeAndID?.id?.value || task?.id;

  useEffect(() => {
    if (!taskId) return;
    // Load the tasks stats
    setIsLoading(true);

    TasksService.getTaskParams(taskId)
      .then((res) => {
        setTaskParams(res.data);
        return TasksService.getTaskStats(taskId);
      })
      .then((res) => {
        if (res.data instanceof Object) {
          // Find the logs for this task
          setTaskStats({ ...(res.data || {}) });
        }
      })
      .catch((e) => {
        // Ignore error, just show no logs
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container fluid style={{ padding: '2em' }}>
      <Row className="p-0">
        <div className="container p-0">
          <Button size="sm" variant="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Row>
      <Row className="align-items-center">
        <Col xl className="p-0">
          <h4 className="d-flex my-2 p-0 align-items-center">
            <div>Task:</div>
            <div className="small text-secondary px-2">{taskId}</div>
            {isLoading && <Spinner as="div" animation="border" size="sm" className="mx-2" variant="secondary" />}
          </h4>
        </Col>
      </Row>
      <Row>
        <div className="d-flex" style={{ backgroundColor: 'gray', border: '1px solid black', paddingTop: '1em' }}>
          <pre>
            {taskStats
              ? Object.keys(taskStats)
                  .map((k) => `${k}: ${taskStats[k]}`)
                  .join('\n')
              : isLoading
              ? 'Loading...'
              : 'No logs.'}
          </pre>
        </div>
      </Row>
      {taskParams && (
        <Row className="mt-4">
          <div className="h6 p-0">Starting Parameters</div>
          <div className="d-flex border bg-light p-1 small">
            <pre>{JSON.stringify(taskParams, null, 2)}</pre>
          </div>
        </Row>
      )}
    </Container>
  );
};

const Tasks = (props) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [oldTasks, setOldTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // The task to show the logs and details
  const [taskToShow, setTaskToShow] = useState(null);
  const [sortCriteria, setSortCriteria] = useState({ field: 'createdAt', descending: true });

  function onSortClick(field) {
    setSortCriteria((oldSortCriteria) => {
      let newCriteria = { field: field, descending: true };

      if (oldSortCriteria?.field === field) {
        // Toggle order
        newCriteria.descending = oldSortCriteria?.descending ? false : true;
      }
      return { ...newCriteria };
    });
  }

  const refreshTasks = useCallback(async () => {
    setIsLoading(true);

    return TasksService.getAllTasks()
      .then((res) => {
        const runningTasks = res.data?.runningTasks || [];
        // remove the running tasks from alltasks and save the result as oldTasks
        let allTasks = res.data?.allRegisteredTasks || [];
        runningTasks.forEach((t) => {
          // Remove the entry in allRegisteredTasks if it's already running
          const tIdx = allTasks.findIndex((t1) => t1.id === t.typeAndID?.id?.value);
          if (tIdx >= 0) {
            // Remove
            allTasks.splice(tIdx, 1);
          }
        });
        setTasks(runningTasks);
        setOldTasks(allTasks);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const sortedTasks = [...tasks];
  sortedTasks.sort((a, b) => {
    let result = -1;
    try {
      const d1 = Date.parse(a.awsDetails?.createdAt);
      const d2 = Date.parse(b.awsDetails?.createdAt);
      result = d1 - d2;
    } catch (_) {
      // Ignore error
    }
    if (sortCriteria?.descending) result = result * -1;
    return result;
  });

  useEffect(async () => {
    let interval = null;
    // Check user login
    try {
      await refreshTasks();
      interval = setInterval(() => {
        refreshTasks();
      }, TASKS_REFRESH_PERIOD_MS);
    } catch (e) {
      // Ignore error. Login page will show
    }
    return () => clearInterval(interval);
  }, [refreshTasks]);

  const stopTask = useCallback(
    (taskArn) => {
      setIsLoading(true);

      TasksService.stopTask(taskArn)
        .then((res) => {
          // console.log('after stoptask', res)
        })
        .catch((e) => {
          console.log('ERROR in stopTask: ', e);
        })
        .finally(() => {
          refreshTasks();
        });
    },
    [refreshTasks]
  );

  const startNewTask = useCallback(
    (taskParams) => {
      setIsLoading(true);
      TasksService.startNewTask(taskParams)
        .then((res) => {})
        .catch((e) => {
          console.log(`Error`, e);
        })
        .finally(() => {
          refreshTasks();
        });
    },
    [refreshTasks]
  );

  return taskToShow ? (
    <TaskDetails task={taskToShow} onClose={() => setTaskToShow(null)} />
  ) : (
    <Container fluid>
      <NewTaskModal isShown={showAddModal} setShown={setShowAddModal} title="Add New Task" startNewTask={startNewTask} />
      <Row className="align-items-center">
        <Col xl className="m-0 p-0">
          <h4 className="d-flex my-1 mx-1 p-0 align-items-center">
            Diameter Client Tasks {isLoading && <Spinner as="div" animation="border" size="sm" className="mx-2" variant="secondary" />}
          </h4>
        </Col>
        <Col xs className="d-flex flex-row-reverse  p-1">
          <Button size="md" variant="outline-primary" onClick={() => setShowAddModal(true)}>
            Add New Task
          </Button>
        </Col>
      </Row>
      <Row>
        <Table hover bordered>
          <thead className="bg-dark text-light">
            <tr>
              <th style={{ width: '%0.1' }}>#</th>
              <th>Task ID</th>
              <th>
                <div className="d-flex flex-row align-items-center" style={{ cursor: 'pointer' }} onClick={() => onSortClick('createdAt')}>
                  <div style={{ marginRight: '3px' }}>Created At</div>
                  {sortCriteria?.descending ? <FaSortDown /> : <FaSortUp />}
                </div>
              </th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedTasks.map((l, i) => {
              return (
                <tr key={i}>
                  <td className="font-monospace align-middle" style={{ width: '0.1%' }}>
                    {i + 1}
                  </td>
                  <td className="align-middle" style={{ width: '30em' }}>
                    <Button variant="link" onClick={() => setTaskToShow(l)} className="m-0 p-0 font-monospace">
                      {l.typeAndID?.id?.value}
                    </Button>
                  </td>
                  <td className="font-monospace align-middle" style={{ width: '20em' }}>
                    {formatDate(l.awsDetails?.createdAt)}
                  </td>
                  <td className="align-middle">{l.awsDetails?.lastStatus}</td>
                  <td className="align-middle">
                    <Button size="sm" variant="danger" className="align-items-center" onClick={() => stopTask(l.awsDetails?.taskArn)}>
                      <FaStop />
                    </Button>
                  </td>
                </tr>
              );
            })}
            {tasks && tasks.length === 0 && (
              <tr>
                <td colSpan="5">No Running Tasks</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      <Row>
        <OldTasksTable oldTasks={oldTasks} deleteTask={stopTask} setTaskToShow={setTaskToShow} />
      </Row>
    </Container>
  );
};

export default Tasks;
