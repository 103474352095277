import React, { useRef, useState, useCallback } from 'react';
import Editor from '@monaco-editor/react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import defaultTaskParameters from './defaultTaskParameters';
import { pd } from 'pretty-data';

const NewTaskModal = (props) => {
  const { isShown, setShown, title, startNewTask } = props;
  const [addEnabled, setAddEnabled] = useState(true);
  const [validationResult, setValidationResult] = useState(null);
  const editorRef = useRef(null);

  const validateParams = useCallback((newValue) => {
    let isParamsValid = false;
    try {
      const p = JSON.parse(newValue);
      isParamsValid = true;
    } catch (e) {
      // Error parsing parameters.
      // isParamsValid = false;
    }
    setAddEnabled(isParamsValid);
  }, []);

  const onClose = useCallback(() => {
    setAddEnabled(true);
    setValidationResult(null);
    setShown(false);
  }, []);

  const onFormatJson = useCallback(() => {
    let beautifiedJson = editorRef?.current?.getValue();
    setValidationResult(null);
    try {
      beautifiedJson = pd.json(beautifiedJson);
      editorRef?.current?.setValue(beautifiedJson);
    } catch (e) {
      // Do nothing in case there's an error
      console.error(e);
      setValidationResult({ error: true, message: 'Not a valid JSON. Check errors.' });
    }
  }, [editorRef]);

  return (
    <Modal show={isShown} onHide={onClose} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group row">
          <label className="col-form-label col">Parameters:</label>
          <div className="d-flex col-sm-10 flex-column" style={{ height: '25em', overflow: 'hidden' }}>
            <div className="d-flex flex-row mb-1">
              {validationResult?.error && (
                <div className="px-2 small py-1" style={{ backgroundColor: '#F5B7B7', borderRadius: '0.3em' }}>
                  {validationResult.message}
                </div>
              )}
              <div className="align-self-center" style={{ marginLeft: 'auto' }}>
                <Button size="sm" variant="secondary" className="py-0 my-1" onClick={onFormatJson}>
                  AutoFormat
                </Button>
              </div>
            </div>
            <Editor
              language="json"
              className="form-control form-control-sm p-0"
              defaultValue={JSON.stringify(defaultTaskParameters, null, '  ')}
              onMount={(ed) => {
                editorRef.current = ed;
              }}
              onChange={(newValue) => {
                validateParams(newValue);
              }}
              options={{
                scrollBeyondLastLine: false,
                minimap: { enabled: false },
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!addEnabled}
          onClick={() => {
            try {
              const paramsString = editorRef?.current?.getValue();
              const params = JSON.parse(`${paramsString}`);
              startNewTask?.(params);
            } catch (e) {
              console.error('CAnt parse', e);
            }
            setShown(false);
          }}
        >
          Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTaskModal;
