import React, { useCallback, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Tasks from './Tasks';
import QuadifiedLogo from './assets/images/Quadified_H.png';
import SignInForm from './SignInForm';
import authService from './services/auth.service';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [authenticatedSession, setAuthenticatedSession] = useState(null);

  const checkAuthSession = useCallback(async () => {
    // Check user signing
    try {
      const currentSession = await authService.getCurrentSession();
      setAuthenticatedSession({ ...currentSession, user: await authService.getCurrentUserInfo() });
    } catch (e) {
      // Ignore error. Sign In page will show
      setAuthenticatedSession(null);
    }
  }, []);

  useEffect(async () => {
    await checkAuthSession();
    setIsLoading(false);
  }, []);

  const onSignOut = useCallback(async () => {
    await authService.signOut();
    setAuthenticatedSession(null);
  }, []);

  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand href="/">
          <img src={QuadifiedLogo} alt="Quadified Logo" height="40" className="m-1" />
        </Navbar.Brand>
        <Navbar.Collapse>
          <Navbar.Text>Diameter Simulator Control Panel</Navbar.Text>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end mx-3">
          {authenticatedSession && (
            <>
              <Navbar.Text className="mx-2" style={{ fontSize: '0.75rem' }}>
                Signed in as: <b>{authenticatedSession.user?.attributes?.email}</b>
              </Navbar.Text>
              <Button variant="outline-secondary" onClick={onSignOut} size="sm">
                Sign Out
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
      {isLoading ? (
        <div className="h3 m-1">Loading...</div>
      ) : authenticatedSession === null ? (
        <SignInForm onAuthenticatedSignin={() => checkAuthSession()} />
      ) : (
        <Tasks />
      )}
    </>
  );
};

export default App;
