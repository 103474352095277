export default {
  endpoint_config: {
    app_name: 'quadified-diameter-client',
    host: 'dia-client.quadified.com',
    realm: 'quadifed.com',
    vendor_id: 10415,
    ip_addresses: ['127.0.0.1'],
  },
  client_config: {
    addr: '<>',
    network_type: 'tcp',
    retries: 3,
    watchdog_interval: 10,
    app_id: 16777251,
  },
  tfc_config: {
    repeat_count: 10,
    tps: 1,
    message_sequence: [
      {
        request: {
          command_code: 316,
          app_id: 16777251,
          avps: [
            {
              code: 'Session-Id',
              flags: 64,
              vendor_id: 0,
              data: 'session;quadified;__RAND????__RAND',
              data_type: 'UTF8String',
            },
            {
              code: 'Origin-Host',
              flags: 64,
              vendor_id: 0,
              data: 'dia-client.quadified.com',
              data_type: 'DiameterIdentity',
            },
            {
              code: 'Origin-Realm',
              flags: 64,
              vendor_id: 0,
              data: '__RAND_SETquadified.com,???????__RAND_SET',
              data_type: 'DiameterIdentity',
            },
            {
              code: 'Destination-Realm',
              flags: 64,
              vendor_id: 0,
              data: 'quadified.com',
              data_type: 'DiameterIdentity',
            },
            {
              code: 'Destination-Host',
              flags: 64,
              vendor_id: 0,
              data: 'dia-server.quadified.com',
              data_type: 'DiameterIdentity',
            },
            {
              code: 'User-Name',
              flags: 64,
              vendor_id: 0,
              data: '__RAND11111?????__RAND',
              data_type: 'UTF8String',
            },
            {
              code: 'Auth-Session-State',
              flags: 64,
              vendor_id: 0,
              data: 0,
              data_type: 'Enumerated',
            },
            {
              code: 'RAT-Type',
              flags: 64,
              vendor_id: '${CONFIG()}',
              data: 1004,
              data_type: 'Enumerated',
            },
            {
              code: 'ULR-Flags',
              flags: 192,
              vendor_id: '${CONFIG()}',
              data: 34,
              data_type: 'Unsigned32',
            },
            {
              code: 'Visited-PLMN-Id',
              flags: 192,
              vendor_id: '${CONFIG()}',
              data: '__RAND_HEX3__RAND_HEX',
              data_type: 'OctetString',
            },
          ],
        },
        answer: {
          result_code: 2001,
        },
        answer_timeout: 10,
        verify_answer: false,
      },
    ],
  },
};
